import React from "react"

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="flex items-center w-full">
      <div className="container flex items-center p-5">&copy; {currentYear} Shumei Arts Council</div>
    </footer>
  )
}
