import React, { Component } from "react"
import Navbar from "../components/Navbar"
import Hero from "../components/Hero"
import About from "../components/About"
import Contact from "../components/Contact"
import Events from "../components/Events"
export default class Home extends Component {
  render() {
    return (
      <>
        <Navbar isHome={true} />
        <Hero />
        <Events upcomingEvents={true} currentEvents={true} />
        <About />
        <Contact />
      </>
    )
  }
}
