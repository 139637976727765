import React, { useRef, useEffect } from "react"
import PhoneIcon from "../assets/images/icons/phone.svg"
import MapPinIcon from "../assets/images/icons/map-pin.svg"
import MailIcon from "../assets/images/icons/mail.svg"
import mapboxgl from "mapbox-gl"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const SimpleMap = () => {
  const mapContainerRef = useRef(null)

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/dark-v10",
      center: [-118.10039126176639, 34.146056580160156],
      zoom: 11,
      attributionControl: false,
    })

    map.scrollZoom.disable()
    map.on("load", () => {
      const marker = new mapboxgl.Marker({ color: "#f51500" })
        .setLngLat([-118.10039126176639, 34.146056580160156])
        .addTo(map)
    })

    // const el = document.createElement('div');
    // el.className = 'marker';

    // new mapboxgl.Marker().setLngLat([-118.10039126176639, 34.146056580160156]).addTo(map)

    // add navigation control (the +/- zoom buttons)
    // map.addControl(new mapboxgl.NavigationControl(), "bottom-right")

    // clean up on unmount
    return () => map.remove()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return <div className="contact-map" ref={mapContainerRef} />
}

export default function Contact() {
  return (
    <section id="contact">
      <div className="row">
        <div className="contact-details">
          <div>
            <h1 className="text-2xl font-bold">Contact Us</h1>
            <div className="contact-item">
              <a
                href="https://goo.gl/maps/gQQmAHUEtjgYpQTe9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={MapPinIcon} alt="Map pin icon" />
                <span>2430 East Colorado Blvd., Pasadena, CA 91107</span>
              </a>
            </div>
            <div className="contact-item">
              <a href="tel:6265848841">
                <img src={PhoneIcon} alt="Phone icon" />
                <span>626 584 8841</span>
              </a>
            </div>
            <div className="contact-item">
              <a href="mailto:info@shumeiarts.org">
                <img src={MailIcon} alt="Email icon" />
                <span>info@shumeiarts.org</span>
              </a>
            </div>

            {/* <div>
              <img src={PhoneIcon} alt="Phone icon" />
              <a href="tel:6265848841">626 584 8841</a>
            </div>
            <div>
              <img src={MailIcon} alt="Email icon" />
              <a href="mailto:info@shumeiarts.org">info@shumeiarts.org</a>
            </div>
            <div>
              <img src={MapPinIcon} alt="Map pin icon" />
              <a
                href="https://goo.gl/maps/gQQmAHUEtjgYpQTe9"
                target="_blank"
                rel="noopener noreferrer"
              >
                2430 East Colorado Blvd., Pasadena, CA 91107
              </a>
            </div> */}
          </div>
        </div>

        <SimpleMap />
      </div>
    </section>
  )
}
