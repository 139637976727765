import gql from "graphql-tag";

const EVENT_QUERY = gql`
  query Event($slug: String!) {
    events(where: {slug: $slug}) {
      slug
      title
      summary
      description
      start_date
      end_date
      start_time
      end_time
      category {
        id
        name
      }
      image {
        url
      }
    }
  }
`;

export default EVENT_QUERY;