import React, { useEffect, useState } from "react"
import Query from "./Query/index"
import EVENTS_QUERY from "../queries/event/events"
import * as utils from "../utils/events-functions"
import moment from "moment"

export default function Events(props) {
  const [events, setEvents] = useState([])

  useEffect(() => {
    // window.scrollTo(0, 0)
    setEvents(Object.keys(props))
  }, [props])

  return (
    <section id="events" className={`events ${!!(events.length === 1) && "events-page"}`}>
      <div className="container">
        <h1 className="slide-in text-2xl font-bold pb-6">What's Happening</h1>
        <Query query={EVENTS_QUERY} id={null}>
          {({ data: { events } }) => {
            const filteredEvents = events.filter((event) => {
              const isAfter = moment(event.start_date).isAfter(moment())
              const isBetween = moment().isBetween(event.start_date, event.end_date)
              const isSame = moment(event.end_date).isSame(moment())
              return isAfter || isBetween || isSame
            })

            return (
              <div className="events-wrapper grid md:grid-cols-2 md:gap-3">
                {filteredEvents.length ? (
                  filteredEvents
                    .sort((a, b) => moment(a.start_date) - moment(b.start_date))
                    .map((item, index) => {
                      return (
                        <div className="event flex-col" key={item.id}>
                          <a href={`${item.slug}`} className="">
                            <img src={item.image.url} alt="" />
                          </a>
                          <div className="flex flex-col justify-center w-100 p-3" key={index}>
                            <h2 className="font-bold text-xl pt-1 pb-2">{item.title}</h2>
                            <p className="p-0 font-bold">{utils.checkTime(item)}</p>
                            <div>
                              <p className="pt-2">{item.summary}</p>
                              <div>
                                <a href={`${item.slug}`}>
                                  <span>View Details</span>
                                  <i className="fa fa-chevron-right" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                ) : (
                  <p className="p-0">No upcoming events. Please check back again!</p>
                )}
              </div>
            )
          }}
        </Query>
      </div>
    </section>
  )
}
