import React, { Component } from "react"
import { Switch, Route } from "react-router-dom"
import Navbar from "../components/Navbar"
import EventDetails from "./EventDetails"
// import Events from "../components/Events"
// import ArtNight from "./ArtNight"
// import ArtNightTest from "./ArtNightTest"
import PageNotFound from "./PageNotFound"

export default class EventsController extends Component {
  render() {
    return (
      <>
        <Navbar />
        <Switch>
            <Route exact path="/:id" component={EventDetails} />
            {/* <Route exact path="/upcoming" render={() => <Events upcomingEvents={true} />} />
            <Route exact path="/current" render={() => <Events currentEvents={true} />} />
            <Route exact path="/past" render={() => <Events pastEvents={true} />} />
            <Route exact path="/events" render={() => <Events upcomingEvents={true} currentEvents={true} pastEvents={true} />} /> */}
            {/* <Route path="/artnight-2021" exact component={ArtNight} /> */}

            {/* Temp Test... */}
            {/* <Route path="/artnight-2021-draft-0921" exact component={ArtNightTest} /> */}
            <Route component={PageNotFound} />
        </Switch>
      </>
    )
  }
}