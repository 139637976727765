import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { NavHashLink } from "react-router-hash-link"
import Submenu from "./Submenu"
import Logo from "../assets/images/logo-dark.svg"

export default function NavBar(props) {
  const [menu, setMenu] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const handleMenu = () => setMenu(!menu)
  const closeMobileMenu = () => setMenu(false)

  const onHomeClick = () => {
    window.scrollTo(0, 0)
    closeMobileMenu()
  }

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false)
    } else {
      setDropdown(true)
    }
  }

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false)
    } else {
      setDropdown(false)
    }
  }

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowMenu(true)
    } else {
      setShowMenu(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true)
  }, [showMenu])

  return (
    <nav className={`navbar ${props.isHome && "navbar-home"} ${showMenu && "scroll"}`}>
      <div className="container navbar-ctn">
        <Link to="/" className="navbar-logo" onClick={onHomeClick}>
          <img src={Logo} alt="Shumei Arts Council logo" />
        </Link>
        <div className="menu-icon" onClick={handleMenu}>
          <i className={menu ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <ul className={menu ? "nav-menu active" : "nav-menu"}>
          {/* <li className="nav-item">
            <Link to="/events" className="nav-links" onClick={closeMobileMenu}>
              Events
            </Link>
          </li> */}
          {/* <li className="nav-item" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <span className="nav-links" onClick={closeMobileMenu}>
              Events&nbsp;
              <i className="fas fa-chevron-down" />
            </span>
            {dropdown && <Submenu />}
          </li> */}
          {/* <li className="nav-item">
            <NavHashLink smooth to="/events" className="nav-links">
              Events
            </NavHashLink>
          </li> */}
          <li className="nav-item">
            <NavHashLink smooth to="/#about" className="nav-links" onClick={closeMobileMenu}>
              About
            </NavHashLink>
          </li>
          <li className="nav-item">
            <NavHashLink smooth to="/#contact" className="nav-links" onClick={closeMobileMenu}>
              Contact
            </NavHashLink>
          </li>
          <li className="nav-item">
            <a
              href="https://www.paypal.com/biz/fund?id=DD3FJA4D6HY4W"
              className="nav-links"
              target="_blank"
              rel="noopener noreferrer"
              onClick={closeMobileMenu}
            >
              Donate
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
