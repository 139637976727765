import React, { Component } from "react"
import Slider from "react-slick"
import { Parallax } from "react-parallax"
import Img from "../assets/images/musicians.png"
import Img2 from "../assets/images/image4.jpg"
import Img3 from "../assets/images/image7.jpg"
import Img4 from "../assets/images/image5.jpg"
import Logo from "../assets/images/logo-dark.svg"

const sliderImages = [
  { image: Img, alt: "Clyde Montgomery Honorary Youth Concert musicians" },
  { image: Img2, alt: "Robert Crowder print exhibition" },
  { image: Img3, alt: "Los Angeles Chamber Singers" },
  { image: Img4, alt: "Shigaraki ceramic vase" },
]
export default class Hero extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 900,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      cssEase: "ease-in-out",
    }

    return (
      <div className="hero">
        <Parallax strength={400} style={{ height: "100vh" }}>
          <Slider {...settings}>
            {sliderImages.map((img, index) => {
              return <img key={index} src={img.image} alt={img.alt} />
            })}
          </Slider>
        </Parallax>
        <div className="hero__logo">
          <img src={Logo} alt="Shumei Arts Council logo" />
        </div>
      </div>
    )
  }
}
