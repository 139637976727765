import gql from "graphql-tag";

const EVENTS_QUERY = gql`
  query Events {
    events {
      id
      title
      summary
      description
      start_date
      end_date
      start_time
      end_time
      slug
      category {
        id
        name
      }
      image {
        url
      }
    }
  }
`;

export default EVENTS_QUERY;