import React, { Component } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks";
import client from "./utils/apolloClient";
import Home from "./pages/Home"
import EventsController from "./pages/EventsController"
import Footer from "./components/Footer"
import "./themes/theme.scss"
import "./themes/tailwind/output.css"

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ApolloProvider client={client}>
          <ApolloHooksProvider client={client}>
            <div style={{ position: "relative", minHeight: "100vh" }}>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route render={() => <EventsController />} />
              </Switch>
              <Footer />
            </div>
          </ApolloHooksProvider>
        </ApolloProvider>
      </BrowserRouter>
    )
  }
}
