import React from "react"

export default function PageNotFound() {
  return (
    <div className="page-not-found" id="event-details">
      <div className="details-page page-not-found-content">
        <h1>Page Not Found</h1>
        <p>The page you were looking for could not be found.</p>
        <p>
          <a href="/">Back to home</a>
        </p>
      </div>
    </div>
  )
}
