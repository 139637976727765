import moment from "moment"

export function formatDate(date) {
  return moment(date).format('ddd., MMMM Do, YYYY')
}

export function formatTime(time) {
  let string = '2015-03-04T' + time + 'Z'
  return moment.utc(string).format('h:mm A')
}

export function getDay(date) {
  return moment(date).format('l')
}

export function checkTime(item) {
  const startDate = item.start_date ? formatDate(item.start_date) : ""
  const startTime = item.start_time ? `| ${formatTime(item.start_time)}` : ""
  const endDate = item.end_date ? `- ${formatDate(item.end_date)}` : ""
  const endTime = item.end_time ? formatTime(item.end_time) : ""


  if (getDay(item.start_date) === getDay(item.end_date)) {
    return `${startDate} ${startTime} ${endTime ? `- ${endTime}`: '' }`
  }
  return `${startDate} ${startTime} ${endDate} ${endTime ? `| ${endTime}`: ''}`
}