import React from "react"
import { useParams } from "react-router"
import Query from "../components/Query/index"
import EVENT_QUERY from "../queries/event/event"
import * as methods from "../utils/events-functions"

const EventDetails = () => {
  let { id } = useParams();
  return (
    <div id="event-details">
      <Query query={EVENT_QUERY} slug={id}>
        {({ data: { events } }) => {
          return (
            <div className="details-page">
              <div className="details-page__img">
                <img src={events[0].image.url} alt="" />
              </div>
              <div className="details-page__info">
                <h1 className="text-xl font-bold pb-2">{events[0].title}</h1>
                <h3 className="font-bold">{methods.checkTime(events[0])}</h3>
                <div className="details-page__info__description" dangerouslySetInnerHTML={{__html: events[0].description}}></div>
                {events[0].category !== null &&
                  <div className="details-page__info__category">{events[0].category.name}</div>
                }
              </div>
            </div>
          )
        }}
      </Query>
    </div>
  )
}

export default EventDetails;